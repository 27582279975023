import React from 'react'

// notification style
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import APIChangePwd from '../../../api/APIChangePwd';


export default function Passwordsettings(props) {
    toast.configure();
    
    function onChangePwd(event) {
        event.preventDefault();
        var oldPassword = event.target['oldPassword'].value
        var newPassword = event.target['newPassword'].value
        var cfPassword = event.target['cfnewPassword'].value
        if (newPassword !== cfPassword) toast.warning('New password does not match !', { autoClose: 1600 })
        else APIChangePwd(oldPassword, newPassword)
            .then((result) => {
                if (result === 400 || 401) toast.warning('Old password does not match !', { autoClose: 1600 })
                else console.log(result)
            })
            .catch((error) => console.log(error))
    }

    return (
        <>
            <div className="tab-content p-4 p-md-5" id="v-pills-tabContent">
                <h3 className="mb-4">Password Settings</h3>
                <form onSubmit={onChangePwd}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Old password</label>
                                <input type="password" name="oldPassword" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>New password</label>
                                <input type="password" name="newPassword" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Confirm new password</label>
                                <input type="password" name="cfnewPassword" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">Update</button>
                        &nbsp; &nbsp;
                    </div>
                </form>
            </div>
        </>
    )
}
