import constants from '../constants.json'
import axios from 'axios'
require('url');

export default function APIGetTree(token) {


  var data = {}


  const databaseId = localStorage.getItem('database')
  const permissionLevel = localStorage.getItem('permissionLevel')
  var adminObj = {
    "targetId": databaseId
  }

  data = permissionLevel === 'admin' ? adminObj : null;

  var config = {
    method: 'get',
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'locations/tree',
    params: data
  };

  return axios(config)
    .then(result => result)
    .catch(function (error) {
      if (!error.status) {
        console.log('network error')
      }
    });
}

