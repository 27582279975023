import React, { Component } from "react";
import Chart from "react-apexcharts";
import APIGetData from '../../../api/APIGetData'
class TrendIndicators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [{
        name: 'Gpeak',
        type: 'line',
        data: []
      }, {
        name: 'Battery',
        type: 'line',
        data: []
      }, {
        name: 'Temperature',
        type: 'line',
        data: []
      },],
      options: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [2, 2, 2]
        },
        title: {
          text: 'Indicators trending',
          align: 'left',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
            , title: {
              text: 'Time'
            }
          }
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            title: {
              text: "gpeak",
              style: {
                color: '#008FFB',
              }
            },
            tooltip: {
              enabled: true
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          },
          {
            seriesName: 'Battery',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#00E396'
            },
            title: {
              text: "Battery",
              style: {
                color: '#00E396',
              }
            },
            labels: {
              style: {
                colors: '#00E396',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          },
          {
            seriesName: 'Temperature',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#FEB019'
            },
            title: {
              text: "Temperature",
              style: {
                color: '#FEB019',
              }
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          },
          style: {
            fontSize: '11px',
            fontFamily: undefined
          },
          y: {
            formatter: function (val) {
              return Math.round(val * 10000) / 10000;
            }
          },
          x: {
            show: false,
            formatter: function (val) {
              var date = new Date(val);
              var convertedDate = date.toLocaleString('en-GB', { hour12: false })
              return `Time : ` + convertedDate;

            }
          }
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        },
      }
    }
    this.initialState = { ...this.state }
  }

  getTodos() {
    const device = this.props.id;
    var token = localStorage.getItem('token')
    APIGetData(device, token)
      .then((results) => {
        this.props.toggleBlocking()
        // if there is an error
        if (device === null) {
          console.log('please select ID')
        }
        else if (results === undefined) {
          console.log('no data found')
        } else {
          this.setState(this.initialState);
          // THIS METHOD IS TO FIND Y-AXIS MAX LENGTH ARRAY, THEN SET IT TO THE CHART
          let batStamp, tempStamp, gStamp;
          // GET THE VALUES
          results.forEach((r) => {
            if(r._type === 'battery') batStamp = r._stamps;
            if(r._type === 'temperature') tempStamp = r._stamps;
            if(r._type === 'gpeak') gStamp = r._stamps;
          })
          var maxStampLength, newStamp;
          // FIND THE MAX LENGTH
          if(batStamp !== undefined && tempStamp !== undefined && gStamp !== undefined) maxStampLength = Math.max(batStamp.length,tempStamp.length, gStamp.length)
          else {
            var testStamp = [batStamp, tempStamp, gStamp]
            testStamp = testStamp.filter(function( element ) {
              return element !== undefined;
            });
            var index = testStamp.reduce((p, c, i, a) => a[p].length > c.length ? a : i, 0);
            if (testStamp.length > 0) maxStampLength = testStamp[index].length
          }
          
          results.forEach((r) => {
            //  LOOKING FOR THE MAX LENGTH STAMP THAT CORRESPONDING TO THE MAX VALUE FOUNDED BEFORE
             if(r._stamps.length === maxStampLength) {
              newStamp = r._stamps
             }
          })
          results.forEach((r) => {
            if (r._type === 'battery') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Battery' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }
            if (r._type === 'temperature') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Temperature' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }

            if (r._type === 'gpeak') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Gpeak' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }
          })
        }
      })
      .catch(error => console.log('trend indicator api error'))
      
  }
  // ignore the unexpected render.
  getTodos2() {
    const device = this.props.id;
    var token = localStorage.getItem('token')
    APIGetData(device, token)
      .then((results) => {
        // if there is an error
        if (device === null) {
          console.log('please select ID')
        }
        else if (results === undefined) {
          console.log('no data found')
        } else {
          this.setState(this.initialState);
          // THIS METHOD IS TO FIND Y-AXIS MAX VALUE ARRAY, THEN SET IT TO THE CHART
          let batStamp, tempStamp, gStamp;
          // GET THE VALUES
          results.forEach((r) => {
            console.log(r)
            if(r._type === 'battery') batStamp = r._stamps;
            if(r._type === 'temperature') tempStamp = r._stamps;
            if(r._type === 'gpeak') gStamp = r._stamps;
          })
          var maxStampLength, newStamp;
          // FIND THE MAX LENGTH
          if(batStamp !== undefined && tempStamp !== undefined && gStamp !== undefined) maxStampLength = Math.max(batStamp.length,tempStamp.length, gStamp.length)
          else {
            var testStamp = [batStamp, tempStamp, gStamp]
            testStamp = testStamp.filter(function( element ) {
              return element !== undefined;
            });
            var index = testStamp.reduce((p, c, i, a) => a[p].length > c.length ? a : i, 0);
            if (testStamp.length > 0) maxStampLength = testStamp[index].length
          }
          
          results.forEach((r) => {
            //  LOOKING FOR THE MAX LENGTH STAMP THAT CORRESPONDING TO THE MAX VALUE FOUNDED BEFORE
             if(r._stamps.length === maxStampLength) {
              newStamp = r._stamps
             }
          })
          results.forEach((r) => {
            if (r._type === 'battery') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Battery' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }
            if (r._type === 'temperature') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Temperature' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }

            if (r._type === 'gpeak') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Gpeak' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }
          })
        }
      })
      .catch(error => console.log('trend indicator api error'))
      
  }

  componentDidUpdate(prevProps) { 
    if (this.props.id !== prevProps.id) { this.getTodos() }
    if (this.props.onDrag !== prevProps.onDrag || this.props.update !== prevProps.update) { this.getTodos2() }
  }
  
  render() {
    return (
      <>
        <Chart options={this.state.options}
          series={this.state.series}
          type="line"
          width="100%"
          height="84%" />
      </>
    );
  }
}

export default TrendIndicators;