import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@material-ui/core/Typography';
import Circle from '@mui/icons-material/Circle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(1),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, iconColor, labelInfo, color, bgColor, ...other } = props;



  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon sx={{ color: iconColor }} className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function TreeFolder(props) {
  let counter = 0

  const createRoot = (props) => {
    if (props.openSearch) return props.searchResult.children.map((node) => renderTree(node))
    return props.devices.children.map((node) => renderTree(node))
  };

  const renderTree = (nodes) => {
    counter++
    if (nodes.name === "root") return null
    if (props.alertsOnly) {
      if (nodes.color === "Green" || nodes.color === "White") return null
    }
    if (nodes.children.length != 0) {
      return (<StyledTreeItem
        nodeId={counter.toString()}
        labelText={nodes.name}
        labelIcon={Circle}
        iconColor={nodes.color}

        color="#1a73e8"

        bgColor="#e8f0fe"
        onClick={() => {
          if (nodes.type === "MonitoringItemJdbc") {
            props.setDevice(nodes.name)
          }
        }}
      >
        {
          nodes.type === "MonTargetItemJdbc"
            ? nodes.children.map((node) => renderTree(node))
            : null
        }
      </StyledTreeItem>)
    }
    return null//(<TreeItem key={counter} nodeId={counter} label={nodes.name} />)
  };

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {createRoot(props)}
    </TreeView>
  );
}