import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import APIResendCode from '../../../api/auth/APIResendCode'
import APIVerify from '../../../api/auth/APIVerify'
// notification style
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function RegisterAuth(props) {
  const classes = useStyles();
  console.log(props.emailRegister)
  toast.configure();

  const [code, setCode] = useState('');
  const [_email, _setEmail] = useState('');

  function register(event) {
    event.preventDefault();

    if(code.length === 8) {
        var email = props.emailRegister ? props.emailRegister : _email
        console.log(email,code)
        APIVerify(email, code)
        .then(results => {
            if(results.status === 403) {
                toast.error('Invalid code', { autoClose: 1600 })
            } else {
                toast.success('Account create successful!', { autoClose: 1600 })
                props.history.push(props.redirectPathOnSuccess)
            }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      toast.error('Invalid code', { autoClose: 1600 })
    }
  }

  function resendCode(event) {
    event.preventDefault();
    var email = props.emailRegister ? props.emailRegister : _email
    if (email.length > 4) {
      APIResendCode(email)
        .then((result) => {
            if(result.status === 200) toast.success('A new code sent to ' +email , { autoClose: 1600 })
        })
        .catch((error) => console.error(error))
    } else toast.error('Invalid email', { autoClose: 1600 })
  }


  function hasEmail() {
    const isHasEmail = props.emailRegister;
    if (!isHasEmail) {
      return <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Email"
            name="email"
            autoComplete="email"
            max
            autoFocus
            value={_email}
            onChange={(e) => {_setEmail(e.target.value)}}
          />;
    }
    return <></>;
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <FingerprintIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
         Verify
        </Typography>
        <Typography variant="caption">Enter the security code. Please check that you received the code in your email address. The code has 8 digits.</Typography>
        <form className={classes.form} onSubmit={register}>
          {hasEmail()}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Digit numbers"
            name="code"
            autoComplete="email"
            max
            value={code}
            onChange={(e) => {setCode(e.target.value)}}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={false}
          >
            Verify Account
          </Button>
          <Grid container>
            <Grid item xs>
                <Link onClick={resendCode} variant="body2" style={{ textDecoration: 'none' }}>
                  {"Resend new code"}
                </Link>
            </Grid>
            <Grid item>
              <Link to ="/" variant="body2" style={{ textDecoration: 'none' }}>
                {"Cancel creating account"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
  
}