import React, { useState, useEffect } from 'react';
import useDebounce from './useDebounce';
import APIGetTree from '../../../api/APIGetTree';
import APIRefreshToken from '../../../api/APIRefreshToken';
import './devices.css';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SyncIcon from '@material-ui/icons/Sync';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader-spinner';
import TreeFolder from './TreeFolder';

const Locations = (props) => {
    const [devices, setDevices] = useState([]);
    const [search, setSearch] = useState("");
    const [openSearch, setOpenSearch] = useState(false);
    const [device, setDevice] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [alertsOnly, setAlertsOnly] = useState(false);
    const [searchResult, setSearchResult] = useState({ children: [] });

    useEffect(() => {
        toDos(token);
    }, [token]);

    useEffect(() => {
        if (props.status !== undefined) {
            var token = localStorage.getItem('token');
            toDos(token);
        }
    }, [props.status]);

    useDebounce(() => {
        function searchJSON(obj, searchTerm, results = []) {
            if (!searchTerm) return
            if (Array.isArray(obj)) {
                obj.forEach(item => searchJSON(item, searchTerm, results));
            } else if (typeof obj === 'object') {
                Object.keys(obj).forEach(key => {
                    if (typeof obj[key] === 'string' && obj[key].toLowerCase().includes(searchTerm.toLowerCase())) {
                        results.push(obj);
                        return;
                    }
                    searchJSON(obj[key], searchTerm, results);
                });
            }
        }
        const searchResults = [];
        searchJSON(device, search, searchResults);
        setSearchResult({ children: searchResults });
    }, [device, search], 800
    );

    const toDos = (token) => {
        /* if (token) {
            APIGetTree(token)
                .then((locations) => {
                    if (locations === undefined) {
                        console.log('no data');
                    } else {
                        console.log('loaded devices !');
                        setDevices(locations.data);
                        setDevice(locations.data);
                        setLoading(false);
                    }
                })
                .catch(error => console.log('get devices error'));
        } */

        if (token) {
            const refreshToken = localStorage.getItem('refreshToken')
            APIRefreshToken(refreshToken)
                .then((results) => {
                    if (results === 401 || results === 403) {
                        alert('Please login again !')
                        props.onLogOut()
                        props.history.push(props.redirectPathOnSuccess)
                        window.location.reload(false);
                    } else {
                        setToken(results.token)

                        APIGetTree(token)
                            .then((locations) => {
                                if (locations === undefined) {
                                    console.log('no data');
                                } else {
                                    setDevices(locations.data);
                                    setDevice(locations.data);
                                    setLoading(false);
                                }
                            })
                            .catch(error => console.log('get devices error'))
                    }
                })
        }
    };

    const onOpenSearch = () => {
        if (openSearch) setSearchResult({ children: [] })
        setOpenSearch(!openSearch);
    };

    const onSort = () => {
        setAlertsOnly(!alertsOnly);
    };

    const onUpdate = () => {
        toDos(token);
        toast.success('Updated devices', { autoClose: 1600 });
    };

    const updateSearch = (event) => {
        setSearch(event.target.value.substr(0, 20));
    };

    toast.configure();
    if (loading) {
        const token = localStorage.getItem('token');
        if (token) return (<Loader type="TailSpin" color="#00BFFF" timeout={4000} height={80} width={80} />);
        else return null;
    } else {
        return (
            <>
                <div style={{ marginLeft: '5%' }}>
                    <Tooltip title="Show only warnings and alarms">
                        <IconButton onClick={onSort}>
                            <WarningIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Search for a device">
                        <IconButton onClick={onOpenSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Update devices">
                        <IconButton onClick={onUpdate}>
                            <SyncIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <input type="text" className={openSearch ? 'form-control searchInput' : 'hidden'} placeholder="Device name"
                    value={search} onChange={updateSearch}
                ></input>
                <TreeFolder devices={devices} setDevice={props.setDevice} openSearch={openSearch} searchResult={searchResult} alertsOnly={alertsOnly} />
            </>
        );
    }
};

export default Locations;
