import React, { Component } from 'react'
import Popup from 'reactjs-popup';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import BuildIcon from '@material-ui/icons/Build';
import APISetLimit from '../../../api/APISetLimit'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import APIGetLimit from '../../../api/APIGetLimit'
export default class SetLimitBtn extends Component {
    constructor(props) {
        super(props)
        this.state = {
          open: false,
          value: "",
          warning: null,
          alarm: null
        }
        this.initialState = { ...this.state }
        this.handleChange = this.handleChange.bind(this);
        this.setLimit = this.setLimit.bind(this);
    }

    setLimit(event) {
        event.preventDefault();
        const inputwarn = event.target['warningLimit'].value
        const inputalarm = event.target['alarmLimit'].value
        const signal = this.props.id + " " + this.state.value

        // if there are any values from api => {value} = APIValue / else return state
        const warning = inputwarn.length > 0 ?  inputwarn : this.state.warning
        const alarm = inputalarm.length > 0 ?  inputalarm : this.state.alarm
        const token = localStorage.getItem('token')
        APISetLimit(signal, warning, alarm, token)
            .then(results => {
              if(results.status === 200) {
                this.props.onUpdate()
                this.setState({open: false})
                toast.success('Set new limit success', { autoClose: 1600 })
              }
            })
            .catch(error => {
              toast.warning('Wrong limit value', { autoClose: 1600 })
            })
        
    }

    handleChange(event) {
        this.getLimit(event);
        this.setState({value: event.target.value});
     }

    getLimit(event) {
      const signal = this.props.id + " " + event.target.value
      const token = localStorage.getItem('token')
        APIGetLimit(signal, token)
          .then(results => {
            if(results.length === 0) {
              this.setState({warning: null, alarm: null})
            } else {
              results.forEach( (e) => {
                if(e._level === 1) this.setState({warning: e._limit_high})
                else this.setState({alarm: e._limit_high})
              })
            }
          })
          .catch(error => console.log(error))

    }

     componentDidUpdate(prevProps) {
      if (this.props.id !== prevProps.id) {
        this.setState(this.initialState);
      }
    }

    onOpen = () => {
      this.setState(this.initialState);
    }

    render() {
        toast.configure();
        const open = false
        const signal = ["vrms", "signal gpeak", "envelope", "gpeak", "battery", "temperature"]
        const selection = signal.map((e, i) => {
            return <option key={i} value={e}>{e}</option>
        })
        
          
        return (
            <>
                <Popup onOpen={this.onOpen} trigger={
                <Tooltip title="Set limit">
                  <IconButton aria-owns={open ? 'menu-appbar' : null} aria-haspopup="true">
                    <BuildIcon style={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>} modal>
              {close => (
                this.props.id !== null ?
                  <div >
                    {/* form */}
                    <form onSubmit={this.setLimit}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputState">Signal</label>
                          <select id="inputState" className="form-control" value={this.state.value} onChange={this.handleChange}>
                            <option defaultValue name='signal'>Please choose a signal...</option>
                            {selection}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAddress">Warning limit</label>
                        <input type="text" name="warningLimit" className="form-control" id="inputAddress" placeholder={this.state.warning === null ? 'No limit available' : this.state.warning} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAddress2">Alarm limit</label>
                        <input type="text" name="alarmLimit" className="form-control" id="inputAddress2" placeholder={this.state.alarm === null ? 'No limit available' : this.state.alarm} />
                      </div>

                      <button type="submit" className="btn btn-primary">Submit</button>
                    </form>

                    <button className="close" onClick={() => {close()}}>&times;</button>
                  </div>
                  : <>Please select a device</>
              )}
              </Popup>
            </>
        )
    }
}
