import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom'
import Locations from '../mainpages/locations/Locations'
import { useTheme } from '@material-ui/core/styles';
import { Typography, IconButton, Toolbar, AppBar, CssBaseline, Drawer } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { btnLogOut ,btnSelectDb, btnCurrentDb } from './BtnNavbar'
import { style } from './style';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useHistory } from "react-router-dom";

export default function PersistentDrawerLeft(props) {
  
  const classes = style();
  
  const theme = useTheme();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setDisplay(true)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setDisplay(false)
  };

  var token = localStorage.getItem('token');
  // auto close ^ open whenever user login - logout
  useEffect(function persistForm() {
    if (props.status || token) setOpen(true)
    // if(permissionLevel) setOpen(false)
    else setOpen(false)
  }, [props.status]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            <Link to={props.status ? '/devices' : "/"} className="linkBtn">
              {/* <img src="/logo.png" alt="image" className={clsx(classes.homeIcon)} /> */}
              Chess Software
            </Link>
          </Typography>
          
          {btnSelectDb()}

          {btnCurrentDb()}

          {token &&<IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              aria-controls='primary-search-account-menu'
              onClick={() => history.push("/account")}
              color="inherit"
              className={clsx(classes.toggleIcon)}
            >
              <AccountCircle />
          </IconButton>}
          
          {btnLogOut(token, props)}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton color="inherit" onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Locations setDevice={props.setDevice} checkToken={props.checkToken} port={props.port} status={props.status} />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

      </main>
    </div>
  );
}