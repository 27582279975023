import React from 'react'

export default function Accountsettings(props) {

    const name = localStorage.getItem('username')

    function onUpdate(event) {
        event.preventDefault();
        alert('update soon')
    }

    return (
        <>
            <div className="tab-content p-md-5" id="v-pills-tabContent">
                <div className="tab-pane alpha show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                    <h3 className="mb-4">Account Settings</h3>
                    <form onSubmit={onUpdate}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" defaultValue={name} />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control" defaultValue="Updating soon@gmail.com" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary">Update</button>
                            &nbsp; &nbsp;
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
