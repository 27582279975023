import React from 'react'
import Envelope from './trend/Envelope'
import Battery from './trend/Battery'
import Gpeak from './trend/Gpeak'
import SignalGpeak from './trend/SignalGpeak'
import Temperature from './trend/Temperature'
import Vrms from './trend/Vrms'
import APIGetData from '../../../api/APIGetData'
//import APIGetAlarms from '../../../api/APIGetAlarms'
import APIGetLimit from '../../../api/APIGetLimit'
import toTime from './function/toTime'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Card, CardGroup, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
//import Loader from 'react-loader-spinner';

export default class Trend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      battery: 0,
      env: 0,
      gpeak: 0,
      signalGpeak: 0,
      temperature: 0,
      vrms: 0,
      tsIndicators: 0,
      tsTimesignal: 0,
      alarmSP: null, alarmVRMS: null, alarmEnv: null,
      alarmGpeak: null, alarmBattery: null, alarmTempe: null,
      receivedAlarm: false,
      warningSP: null, alertSP: null,
      warningVRMS: null, alertVRMS: null,
      warningEnv: null, alertEnv: null,
      warningGpeak: null, alertGpeak: null,
      warningBattery: null, alertBattery: null,
      warningTempe: null, alertTempe: null
    };
    this.initialState = { ...this.state }
  }

  async getTodos() {
    var token = localStorage.getItem('token')
    APIGetData(this.props.device, token)
      .then((results) => {
        // if there is an error
        if (this.props.device === null) {
          toast.warning('Please select a device!', { autoClose: 1600 })
        }
        else if (results === undefined) {
          toast.warning('No data found', { autoClose: 1600 })
        } else {
          results.forEach((r) => {
            if (r._type === 'battery') { this.setState({ battery: r._magnitudes.slice(-1)[0], tsIndicators: r._stamps.slice(-1)[0] }); }
            if (r._type === 'envelope') { this.setState({ env: r._magnitudes.slice(-1)[0], tsTimesignal: r._stamps.slice(-1)[0] }); }
            if (r._type === 'gpeak') { this.setState({ gpeak: r._magnitudes.slice(-1)[0], tsIndicators: r._stamps.slice(-1)[0] }) }
            if (r._type === 'signal gpeak') { this.setState({ signalGpeak: r._magnitudes.slice(-1)[0], tsTimesignal: r._stamps.slice(-1)[0] }); }
            if (r._type === 'temperature') { this.setState({ temperature: r._magnitudes.slice(-1)[0], tsIndicators: r._stamps.slice(-1)[0] }); }
            if (r._type === 'vrms') { this.setState({ vrms: r._magnitudes.slice(-1)[0], tsTimesignal: r._stamps.slice(-1)[0] }); }
          })
        }
      })
      .catch()

    /* APIGetAlarms(token, this.props.device)
      .then((results) => {
        console.log(results)
        if (results !== undefined) {
          var signalGpeak = this.props.device + " signal gpeak"
          var vrms = this.props.device + " vrms"
          var envelope = this.props.device + " envelope"
          var gpeak = this.props.device + " gpeak"
          var battery = this.props.device + " battery"
          var temperature = this.props.device + " temperature"
          this.setState({
            receivedAlarm: true,
            alarmSP: results[0][signalGpeak] === undefined ? 0 : results[0][signalGpeak],
            alarmVRMS: results[0][vrms] === undefined ? 0 : results[0][vrms],
            alarmEnv: results[0][envelope] === undefined ? 0 : results[0][envelope],
            alarmGpeak: results[0][gpeak] === undefined ? 0 : results[0][gpeak],
            alarmBattery: results[0][battery] === undefined ? 0 : results[0][battery],
            alarmTempe: results[0][temperature] === undefined ? 0 : results[0][temperature],
          })
        }
      })
      .catch((error) => console.log(error)) */
  }

  alarmNoti = (device) => {
    var { warningSP, warningVRMS, warningEnv, warningGpeak, warningBattery, warningTempe } = this.state
    var { alertSP, alertVRMS, alertEnv, alertGpeak, alertBattery, alertTempe } = this.state
    var { signalGpeak, vrms, env, gpeak, battery, temperature } = this.state
    if (device) {
      let warning = [], red = [];
      if (warningSP && alertSP) {
        if (signalGpeak >= alertSP) red.push('Signal Gpeak alarm!')
        else if (signalGpeak >= warningSP) warning.push('Signal Gpeak Warning!')
      }
      if (warningVRMS && alertVRMS) {
        if (vrms >= alertVRMS) red.push('VRMS alarm!')
        else if (vrms >= warningVRMS) warning.push('VRMS Warning!')
      }
      if (warningEnv && alertEnv) {
        if (env >= alertEnv) red.push('Envelope alarm!')
        else if (env >= warningEnv) warning.push('Envelope Warning!')
      }
      if (warningGpeak && alertGpeak) {
        if (gpeak >= alertGpeak) red.push('Gpeak alarm!')
        else if (gpeak >= warningGpeak) warning.push('Gpeak Warning!')
      }
      if (warningBattery && alertBattery) {
        if (battery <= alertBattery) red.push('Very low battery!')
        else if (battery <= warningBattery) warning.push('Low battery!')
      }
      if (warningTempe && alertTempe) {
        if (temperature >= alertTempe) red.push('Temperature alarm!')
        else if (temperature >= warningTempe) warning.push('Temperature warning!')
      }

      // red popup
      const redElements = red.map(e => {
        return <Popover.Content>
          {e}
        </Popover.Content>
      })
      const redPopup = (
        <Popover>
          {redElements}
        </Popover>
      );

      // warning popup
      const warningElements = warning.map(e => {
        return <Popover.Content>
          {e}
        </Popover.Content>
      })
      const warningPopup = (
        <Popover>
          {warningElements}
        </Popover>
      );

      if (warning.length === 0 && red.length === 0)
        return <Alert variant='success'>
          <strong>No alarms</strong>
        </Alert>

      else if (warning.length > 0 && red.length === 0) {
        return <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={warningPopup}
        >
          <Alert variant='warning' size="sm">
            <strong>Warning messages! Hover to see</strong>
          </Alert>
        </OverlayTrigger>
      } else if (warning.length === 0 && red.length > 0) {
        return <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={redPopup}
        >
          <Alert variant='danger' size="sm">
            <strong>Alert messages! Hover to see</strong>
          </Alert>
        </OverlayTrigger>
      } else return <div>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={warningPopup}
        >
          <Alert variant='warning' size="sm">
            <strong>Warning messages! Hover to see</strong>
          </Alert>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={redPopup}
        >
          <Alert variant='danger' size="sm">
            <strong>Alert messages! Hover to see</strong>
          </Alert>
        </OverlayTrigger>
      </div>
    }
  }

  getLimit = () => {
    //console.log('GET LIMIT CALLED')
    const token = localStorage.getItem('token')
    const SP = this.props.device + " signal gpeak"
    const VRMS = this.props.device + " vrms"
    const env = this.props.device + " envelope"
    const gpeak = this.props.device + " gpeak"
    const battery = this.props.device + " battery"
    const tempe = this.props.device + " temperature"
    APIGetLimit(SP, token)
      .then(results => {
        if (results.length === 0) {
          this.setState({ warningSP: null, alertSP: null })
        } else {
          results.forEach((e) => {
            if (e._level === 1) this.setState({ warningSP: e._limit_high })
            else this.setState({ alertSP: e._limit_high })
          })
        }
      })
      .catch(error => console.log(error))

    APIGetLimit(VRMS, token)
      .then(results => {
        if (results.length === 0) {
          this.setState({ warningVRMS: null, alertVRMS: null })
        } else {
          results.forEach((e) => {
            if (e._level === 1) this.setState({ warningVRMS: e._limit_high })
            else this.setState({ alertVRMS: e._limit_high })
          })
        }
      })
      .catch(error => console.log(error))

    APIGetLimit(env, token)
      .then(results => {
        if (results.length === 0) {
          this.setState({ warningEnv: null, alertEnv: null })
        } else {
          results.forEach((e) => {
            if (e._level === 1) this.setState({ warningEnv: e._limit_high })
            else this.setState({ alertEnv: e._limit_high })
          })
        }
      })
      .catch(error => console.log(error))

    APIGetLimit(gpeak, token)
      .then(results => {
        if (results.length === 0) {
          this.setState({ warningGpeak: null, alertGpeak: null })
        } else {
          results.forEach((e) => {
            if (e._level === 1) this.setState({ warningGpeak: e._limit_high })
            else this.setState({ alertGpeak: e._limit_high })
          })
        }
      })
      .catch(error => console.log(error))

    APIGetLimit(battery, token)
      .then(results => {
        if (results.length === 0) {
          this.setState({ warningBattery: null, alertBattery: null })
        } else {
          results.forEach((e) => {
            if (e._level === 1) this.setState({ warningBattery: e._limit_high })
            else this.setState({ alertBattery: e._limit_high })
          })
        }
      })
      .catch(error => console.log(error))

    APIGetLimit(tempe, token)
      .then(results => {
        if (results.length === 0) {
          this.setState({ warningTempe: null, alertTempe: null })
        } else {
          results.forEach((e) => {
            if (e._level === 1) this.setState({ warningTempe: e._limit_high })
            else this.setState({ alertTempe: e._limit_high })
          })
        }
      })
      .catch(error => console.log(error))
  }


  componentDidUpdate(prevProps) {
    if (this.props.updateTS !== prevProps.updateTS || this.props.device !== prevProps.device) {
      this.setState(this.initialState)
      this.getLimit()
      this.getTodos()
    }
  }

  render() {
    toast.configure();
    return (
      <>
        <CardGroup>
          <Card className="mb-2 mr-2 mt-2">
            <Card.Body><p style={{ textAlign: 'left' }}><b>Latest result</b>
              <span style={{ float: 'right', fontStyle: 'italic', fontSize: '13px' }}>
                {this.state.tsTimesignal === 0 ? '' : toTime(this.state.tsTimesignal)}
              </span>
            </p>
            </Card.Body>
            <Card.Body style={{ margin: '-12% 0% 5% 0%' }}>
              <div className="row">
                <SignalGpeak data={this.state.signalGpeak} warning={this.state.warningSP} alert={this.state.alertSP} />
                <Vrms data={this.state.vrms} warning={this.state.warningVRMS} alert={this.state.alertVRMS} />
                <Envelope data={this.state.env} warning={this.state.warningEnv} alert={this.state.alertEnv} />
              </div>
            </Card.Body>
          </Card>
          <Card className="mb-2 mr-2 mt-2">
            <Card.Body><p style={{ textAlign: 'left' }}><b>Latest result</b>
              <span style={{ float: 'right', fontStyle: 'italic', fontSize: '13px' }}>
                {this.state.tsIndicators === 0 ? '' : toTime(this.state.tsIndicators)}
              </span>
            </p>
            </Card.Body>
            <Card.Body style={{ margin: '-12% 0% 5% 0%' }}>
              <div className="row">
                <Gpeak data={this.state.gpeak} warning={this.state.warningGpeak} alert={this.state.alertGpeak} />
                <Battery data={this.state.battery} warning={this.state.warningBattery} alert={this.state.alertBattery} />
                <Temperature data={this.state.temperature} warning={this.state.warningTempe} alert={this.state.alertTempe} />
              </div>
            </Card.Body>
          </Card>
          <Card className="mb-2 col-md-4 mt-2">
            <Card.Body>
              <p><b>Alarms</b></p>
              {this.alarmNoti(this.props.device)}
            </Card.Body>
          </Card>
        </CardGroup>
      </>
    )
  }
}
