import React, { useEffect, useState } from 'react'
import _AdminGetTargets from '../../api/_AdminGetTargets'
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
export default function Selectdatabase(props) {

    const [databases, setDatabases] = useState([])

    useEffect(() => {
        _AdminGetTargets()
            .then(result => setDatabases(result))
            .catch(error => console.log(error))
    }, [])

    const _setDatabase = (id, dbName) => {
        localStorage.setItem('database', id)
        localStorage.setItem('dbName', dbName)
    }

    const permissionLevel = localStorage.getItem('permissionLevel')
    
    let dbDiv = []
    
    if(permissionLevel === 'admin') {
        dbDiv  = databases.map((e, i) => {
            return (
                <div key={i} className="col-sm-6 col-lg-2" >
                    <div className="card border-primary mb-5 " style={{ maxWidth: '15rem', display: 'flex' }} >
                        <div className="card-header"><b>ID</b>: {e.id}</div>
                        <div className="card-body text-dark">
                            <h5 className="card-title">{e.name}</h5>
                            <p className="card-text"><b>Owner: </b>{e.key.owner}</p>
                            <p className="card-text"><b>Key : </b> {e.key.key}</p>
                            <p className="card-text"><b>Details:</b> {e.details}</p>
                            <p className="card-text"><small className="text-muted">{e.url}</small></p>
                            <Button variant="contained" href="/devices" color="primary" onClick={() => _setDatabase(e.id, e.name)}>Select Database</Button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    // here need to improve check token
    if (permissionLevel === 'admin') {
        return (
            <div style={props.isDisplay ? { marginLeft: "200px", width: "88%" } : { marginLeft: "9%", width: "88%" }}>
                <div className="row">
                    {dbDiv}
                </div>
            </div>
    
        )
    } else return <><Redirect to='/'/></>
}

Selectdatabase.defaultProps = {
    isDisplay: false
}