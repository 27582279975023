import React, { useEffect } from 'react'
import APIGetTelegramAlarms from '../../../api/telegram/APIGetTelegramAlarms';
import { Edit, DeleteForeverOutlined } from '@material-ui/icons';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styles from "./TelegramSettings.module.css";

export default function Telegramitems(props) {

    const { items, setItems, deleteItem, editItem } = props

    useEffect(() => {
        APIGetTelegramAlarms()
            .then(res => {
                const { data } = res
                setItems(data)
            })
            .catch(err => console.log(err))
    }, [])

    var Item
    if (items.length > 0) Item = items.map((e, i) => {
        let alarmType
        let destinationType
        let placeholderDestination
        switch (e.type) {
            case "telegram":
                alarmType = "Private Message"
                destinationType = "Telegram Chat ID: "
                placeholderDestination = "1001442873928"
                break;
            case "telegramgroup":
                alarmType = "Group Message"
                destinationType = "Telegram Chat ID: "
                placeholderDestination = "-1001442873928"
                break;
            case "email":
                alarmType = "Email"
                destinationType = "Email address: "
                placeholderDestination = "example@nome.fi"
                break;
        
            default:
                break;
        }
        return (
            <div key={i} className="col-sm-6 col-lg-4" >
                <div className="card border-primary mb-5" style={{ display: 'flex' }} >
                    <div className="card-header"><b>Type</b>: {alarmType}</div>
                    <div className="card-body text-dark">
                        <h5 className="card-title"><b>Name: </b>{e.name}</h5>
                        <p className="card-text"><b>{destinationType}</b>{e.uri}</p>
                        
                        {/* EDIT ITEM */}
                        <Popup trigger={
                            <button type="button" className="btn btn-primary mt-2"><Edit /></button>
                        } modal>
                            {close => (
                                <div className={styles.popupModal}>
                                    &nbsp;
                                    <form onSubmit={editItem} >
                                        <div className="row">
                                            <input name="id" hidden={true} value={e.id}/>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>New name</label>
                                                    <input type="text" name="groupName" className="form-control" placeholder="Eg: data.nome.fi_alarms" />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>New {destinationType}</label>
                                                    <input type="text" name="groupMsgId" className="form-control" placeholder={placeholderDestination} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary">Submit</button>
                                            &nbsp; &nbsp;
                                        </div>
                                    </form>
                                    <button className="close" onClick={close}>
                                        &times;
                                    </button>
                                </div>
                            )}
                        </Popup>

                        {/* DELETE ITEM */}
                        <button type="button" className="btn btn-danger  float-right" onClick={() => deleteItem(e.id)}><DeleteForeverOutlined /></button>
                    </div>
                </div>
            </div>
        )
    })
    else Item = (
        <div className={styles.alarmStatus}>No alarms found.</div>
    )

    return (
        <div className="row mt-4">
            {Item}
        </div>
    )
}
