import React, { Component } from "react";
import Chart from "react-apexcharts";
import options from '../config/singleChart/configVRMS'
import APIGetData from '../../../../api/APIGetData'
import APIGetLimit from '../../../../api/APIGetLimit'

class VRMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
        series: [{
            name: 'VRMS',
            type: 'line',
            data: []
          }],
        options: options.options
    }
  }

  UNSAFE_componentWillMount() { 
    this.getTodos() 
  }

  getTodos() {
    const device = this.props.id;
    const deviceParams = device + " vrms"
    const token = localStorage.getItem("token")

    let middle, high
    APIGetLimit(deviceParams, token)
    .then( (results) => {
      if( typeof(results) === 'string' || results.length === undefined) {
        // nothing received
      } else {
        results.forEach(limit => {
          // console.log(limit)
            if (limit._level === 1) middle = limit._limit_high
            if (limit._level === 3) high = limit._limit_high
        }, [])
      }
    })
    
    APIGetData(device, token)
      .then((results) => {
        // if there is an error
        if (device === null) {
          console.log('please select ID')
        }
        else if (results === undefined) {
          console.log('no data found')
        } else {
          results.forEach((r) => {
            if (r._type === 'vrms') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: r._stamps
                  },
                  annotations: {
                    ...this.state.animations,
                    yaxis: prevState.options.annotations.yaxis.map(
                      el => {
                        if (middle === undefined )   {
                          return ''
                        } else {
                          if(el.label.text === 'Safe') return {...el, y: 0,y2: middle}
                          else if(el.label.text === 'Warning') return {...el, y: middle,y2: high}
                          else if(el.label.text === 'Alert') return {...el, y: high, y2: high+1000}
                        }
                      }
                    ),
                  }
                },
                series: prevState.series.map(
                  el => el.name === 'VRMS' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }
          })
        }
      })
      .catch(error => console.log('400'))
  }

  render() {
    return ( <> <Chart options={this.state.options} series={this.state.series}
                        type="line" width="100%" height="400px" />
             </>);
  }
}
export default VRMS;