import React, { useEffect } from 'react'
import { Tooltip, IconButton } from "@material-ui/core";
import { Add } from '@material-ui/icons';

import styles from "./TelegramSettings.module.css";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import APIGetTelegramAlarms from '../../../api/telegram/APIGetTelegramAlarms';
import APIAddTelegramAlarm from '../../../api/telegram/APIAddTelegramAlarm';
import APIDeleteTelegramAlarm from '../../../api/telegram/APIDeleteTelegramAlarm'
import APIEditTelegramAlarm from '../../../api/telegram/APIEditTelegramAlarm';

import TelegramItems from './TelegramItems'

export default function Telegramsettings(props) {
    toast.configure();

    const [selectedOption, setSelectedOption] = React.useState("Private Message")
    const [items, setItems] = React.useState([])

    function onValueChange(event) {
        setSelectedOption(event.target.value)
    }

    var checkType = () => {
        if (selectedOption === "Private Message") return "telegram"
        else if (selectedOption === "Group Message") return "telegramgroup"
        else return "email"
    }

    function refreshNewItem() {
        console.log('REFRESH NEW ITEM  CALLED')
        APIGetTelegramAlarms()
            .then(res => setItems(res.data))
    }

    // THIS FUNCTION HELP TO SYNC DATA FIRST RENDER
    useEffect(() => {
        refreshNewItem()
    }, [])

    function addTelegram(event) {
        event.preventDefault();
        var groupName = event.target['groupName'].value
        var groupMsgId = event.target['groupMsgId'].value
        var selectedType = checkType()

        APIAddTelegramAlarm(groupName, selectedType, groupMsgId)
            .then(res => {
                if (res.status === 200) {
                    toast.success('Alarm added successfully.')
                } else {
                    toast.warn('Something went wrong.')
                }
            })
            .catch(error => console.log(error))
            .finally(refreshNewItem())
    }

    function deleteItem(id) {
        if (window.confirm('Are you sure you want to delete this alarm?')) {
            APIDeleteTelegramAlarm(id)
                .then(res => {
                    if (res.status === 200) {
                        refreshNewItem()
                        toast.success('Removed alarm.')
                    } else {
                        toast.warn('Something went wrong.')
                    }
                })
        } else {
            console.log('NO DELETE ALARM');
        }
    }

    function editItem(event) {
        event.preventDefault();
        var id = event.target['id'].value
        var name = event.target['groupName'].value
        var uri = event.target['groupMsgId'].value

        APIEditTelegramAlarm(id, name, uri)
            .then(res => {
                if (res.status === 200) {
                    refreshNewItem()
                    toast.success('Edited alarm.')
                } else {
                    toast.warn('Something went wrong.')
                }
            })
            .catch(error => console.log(error))
    }

    let addressType = selectedOption === "Private Message" ? "private" : ""

    switch (selectedOption) {
        case "Private Message":
            addressType = "Telegram Chat ID"
            break;
        case "Group Message":
            addressType = "Telegram Group Chat ID"
            break;
        case "Email Message":
            addressType = "Email Address"
            break;

        default:
            break;
    }

    return (
        <>
            <div className="tab-content p-md-5" id="v-pills-tabContent">
                <div className="tab-pane alpha show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                    <div id={styles.containerIntro}>
                        <h3>Alarms</h3>
                        &nbsp;
                        <Popup trigger={
                            <Tooltip title="Add an alarm">
                                <IconButton>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        } modal>
                            {close => (
                                <div className={styles.popupModal}>
                                    &nbsp;
                                    <form onSubmit={addTelegram} >
                                        <input type="radio" id="private-msg" name="msg_type" value="Private Message" onChange={onValueChange} checked={selectedOption === "Private Message"} />
                                        &nbsp; <label htmlFor="private-msg">Private Message</label>
                                        &nbsp; <input type="radio" id="group-msg" name="msg_type" value="Group Message" onChange={onValueChange} checked={selectedOption === "Group Message"} />
                                        &nbsp; <label htmlFor="group-msg">Group Message</label>
                                        &nbsp; <input type="radio" id="email-msg" name="msg_type" value="Email Message" onChange={onValueChange} checked={selectedOption === "Email Message"} />
                                        &nbsp; <label htmlFor="email-msg" disabled >Email Message</label><br /><br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Alarm name</label>
                                                    <input type="text" name="groupName" className="form-control" placeholder="Eg: data.nome.fi_alarms" />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{addressType}</label>
                                                    <input type="text" name="groupMsgId" className="form-control" placeholder="Eg: -1001442873928" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary">Add</button>
                                            &nbsp; &nbsp;
                                        </div>
                                    </form>
                                    <button className="close" onClick={close}>
                                        &times;
                                    </button>
                                </div>
                            )}
                        </Popup>
                    </div>
                    <TelegramItems items={items} setItems={setItems} deleteItem={deleteItem} editItem={editItem} />
                </div>
            </div>
        </>
    )
}
