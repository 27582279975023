import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";
//import IconButton from "@material-ui/core/IconButton"
import { ExitToApp } from '@material-ui/icons';
import { style } from './style';
//import clsx from 'clsx';

let permissionLevel = localStorage.getItem('permissionLevel')
let dbName = localStorage.getItem('dbName')


const onClearDb = () => {
    localStorage.removeItem('database')
    localStorage.removeItem('dbName')
}

/* const logOutStyle = () => {
    if(dbName) return '30%'
    return '80%' 
} */

const btnLogOut = (token, props) => {
    if (token) 
    return <Tooltip className={style} title="Log-out" style={{ fontSize: '20px' }} >
            <Button href="/" color="inherit" onClick={props.onLogOut}>
                <ExitToApp/>
            </Button>
          </Tooltip>
    return <></>
}

const btnCurrentDb = () => {
    if (dbName) return <Typography style={{ marginLeft: '20%' }} color="inherit">
        DB: {dbName}</Typography>
    return <></>
}

const btnSelectDb = () => {
    if (permissionLevel === 'admin' && dbName) 
    return <Typography style={{ marginLeft: '15%', fontSize: '17px' }} variant="h6">
        <Link href="/databases"  className="linkBtn" onClick={onClearDb}>
        Select other DB</Link>
    </Typography>
    return <></>
}

export {
    btnLogOut,
    btnCurrentDb,
    btnSelectDb
}
