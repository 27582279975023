const test = {
    chart: {
      type: 'area',
      stacked: false,
      height: 100,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      },
      animations: {
        enabled: false
      }
    },
    noData: {
      text: 'No data available !',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: 'black',
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
    },
    title: {
      text: 'Time Signal',
      align: 'left'
      
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(3);
        },
      },
      title: {
        text: 'Gravity'
      },
    },
    xaxis: {
      type: 'numeric',
      categories: [],
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        }
      ,title: {
        text: 'Time'
      } 
      }
    },
    stroke: {
        width: [1, 1, 4],
    },
    tooltip: {
      shared: false,
      x: {
        show: false,
        formatter: function (val, series) {
          return `Value: ` +val.toFixed(4)
        }
      }
    },
    annotations: {
        points: [
          {
            x: 0,
            y: 0,
            hi: 'test',
            marker: {
              size: 6,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560"
              },
              text: "Lastest point"
            }
          }
        ]
      }
  }

module.exports = test
