exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#TelegramSettings_containerIntro__2humO h3,\n#TelegramSettings_containerIntro__2humO p {\n    display: inline;\n    vertical-align: middle;\n}\n\n.TelegramSettings_popupModal__1GZAe {\n    margin: 25px 50px;\n}\n\n.TelegramSettings_telegramItem__3mfhg {\n    margin-top: 1rem;\n}\n\n.TelegramSettings_alarmStatus__3AuoD {\n    margin-left: 1rem;\n    font-size: 16px;\n    font-style: bold;\n}", ""]);

// exports
exports.locals = {
	"containerIntro": "TelegramSettings_containerIntro__2humO",
	"popupModal": "TelegramSettings_popupModal__1GZAe",
	"telegramItem": "TelegramSettings_telegramItem__3mfhg",
	"alarmStatus": "TelegramSettings_alarmStatus__3AuoD"
};