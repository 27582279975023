import constants from '../constants.json'
import axios from 'axios'
export default function APIGetData(device, token) {
  
  var data = {}

  var userObj = {
    "name" : device,
    "days" : "0",
    "num" : "300"
  };

  const databaseId  = localStorage.getItem('database')
  const permissionLevel = localStorage.getItem('permissionLevel')
  var adminObj = {
    "name" : device,
    "days" : "0",
    "num" : "300",
    "targetId": databaseId
  }

  data = permissionLevel === 'admin' ? adminObj : userObj;

  var config = {
    method: 'get', // should change to post, 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'locations/custom',
    params: data
  };
  
  return axios(config)
  .then(response => response.data)
  .catch(function(error) {
    if (!error.status) {
      console.log('get data error')
    }});
}